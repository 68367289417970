/*** Spinner ***/
#spinner {
    position: fixed;
    display: block;
    z-index: 999;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* width: 3.5rem;
    height: 3.5rem; */
    background-color: rgba(249, 237, 237, 0.5);
    width: 100%;
    height: 100%;
    /* transform: translate(-50%, -50%); */
    /* display: none; */
}


.fw-medium {
    font-weight: 400;
}

.bx-chevron-up:before {
    content: '\ea57';
}

.logout-content {
    width: 100%;
}