.p-datatable-table .p-datatable-thead {
  background: var(--thead);
  border-radius: 6px;
}

.p-datatable .p-datatable-thead>tr>th {
  background: none;
  border: none;
  padding: 10px 15px;
  color: var(--colorbtow);
  font-family: 'Open Sans Variable', sans-serif;
}

.p-datatable .p-datatable-tbody>tr {
  background: var(--trodd);
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd {
  background: var(--treven);
}

.p-datatable .p-datatable-thead>tr>th:first-child {
  border-radius: 6px 0 0 6px;
}

.p-datatable .p-datatable-thead>tr>th:last-child {
  border-radius: 0 6px 6px 0;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: var(--thhover);
  color: var(--thhovercolor);
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
  border-width: 0 0 1px 0 !important;
  border-color: var(--tdborder);
  padding: 12px 15px;
  color: var(--bodycolor);
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td .link {
  color: var(--colorbtow);
  margin-right: 5px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--bodycolor);
}

/* Data table grid ends */
/* Paging starts */
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--primary);
  color: var(--white);
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 0 1px 0 !important;
  border-color: var(--tdborder);
  background: var(--bodybg);
}

.p-paginator .p-paginator-current {
  color: var(--bodycolor);
}

.p-paginator .p-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
}

.p-button.p-button-icon-only {
  border-radius: 0 3px 3px 0;
}

.search-form-group {
  position: relative;
}

.search-form-group .btn {
  position: absolute;
  right: 0;
  z-index: 9;
  top: 0;
  padding: 7.5px 4px;
}