.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(245, 243, 243, 0.25);
  box-shadow: inset -1px 0 0 rgba(243, 239, 239, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}
