.p-dropdown {
  display: inline-flex !important;
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
  font-size: large;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

/* .p-dropdown:not(.p-disabled):hover {
    border-color: var(--primary)
} */

.p-dropdown .p-dropdown-trigger {
  justify-content: end;
}

.p-inputtext {
  padding: 0;
  font-size: large;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
