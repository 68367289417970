.content {
  max-height: 100 !important;
  /* padding: 20px 20px 10px; */
  color: #fff;
  /* margin-bottom: 30px; */
}

.content-footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 20px 10px;
}

.colour-1 {
  background: #483c46;
  color: #fff;
}

.colour-2 {
  background: #3c6e71;
  color: #fff;
}

.colour-3 {
  background: #70ae6e;
  color: #fff;
}

.colour-4 {
  background: #82204a;
}

.colour-5 {
  background: #558c8c;
  color: #fff;
}

.colour-6 {
  background: #917c78;
  color: #fff;
}