a {
    color: var(--primary);
    text-decoration: none;
}

a:hover {
    color: var(--light-blue);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans Variable', sans-serif;
}

.page-header {
    background:
        linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        center center no-repeat;
    background-size: cover;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
    color: var(--light);
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
    font-size: 18px;
    color: var(--light);
}

/* .page-content-background {
    background: #F7F3ED;
  } */

.page-content {
    /* width: calc(100% - 70px); */
    /* margin-left: 70px; */
    /* transition: margin .25s ease-out; */
    background: #f7f3ed;
    /* margin-top: 10px; */
    /* text-align: left; */
}

.form-body {
    /* width: calc(100% - 70px);
    margin-left: 70px;
    padding: 20;
    transition: margin .25s ease-out; */
    text-align: left;
    border: 1;
    background:
        linear-gradient(rgba(248, 247, 247, 0.1), rgba(39, 3, 3, 0.1)),
        center center no-repeat;
}