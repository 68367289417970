@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
button,
input,
label,
select,
textarea {
  font-family: 'Open Sans Variable', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body,
html {
  background-color: #fafaf9;
}

.tracking--1percent {
  letter-spacing: -.01em;
}

.tracking--1_5percent {
  letter-spacing: -.015em;
}

.leading-26px {
  line-height: 26.25px;
}

.p-paginator-current {
  overflow: initial;
  cursor: default;
}

.min-h-svh-header {
  min-height: calc(100vh - 100px);
}

.shadow-handle {
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}


/* css overrides styles for react-datepicker */
.react-datepicker {
  font-family: 'Open Sans Variable', sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  display: inline-block;
  position: relative;
  line-height: initial;
  padding: 0.5rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: 0;
}

.react-datepicker__navigation {
  top: 12px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #557122;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: rgb(225 238 200);
}

.react-datepicker-popper .react-datepicker__triangle {
  stroke: #eeeeee;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: #fff;
}

.react-tel-input .flag-dropdown {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.react-tel-input .flag-dropdown:hover {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  background-color: transparent;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: .5rem 0 0 .5rem;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgb(85, 113, 34) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

.text-gray-700 {
  color: #363636;
}
 