/*** Button ***/
.btn {
    transition: 0.5s;
    font-weight: 500;
    padding: 10px 28px;
}

.btn-primary,
.btn-outline-primary:hover {
    background: var(--primary);
    color: var(--white);
}

.btn-primary:hover {
    background: var(--primary);
    color: var(--white);
    opacity: 0.8;
}

.btn-secondary {
    background: var(--secondary);
    color: var(--bodycolor);
}

.btn-secondary:hover {
    background: var(--secondary);
    color: var(--bodycolor);
    opacity: 0.8;
}

.btn-secondary,
.btn-outline-secondary:hover {
    color: var(--dark);
}

.link {
    cursor: pointer;
    color: blue;
}