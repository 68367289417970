input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
    display: block;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
    cursor: pointer;
}

input[type="date"] {
    display: block;
}